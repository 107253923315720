const {allServices, rootNode, allServicesBnb} = require("./allServices");
const {updateServices} = require("./updateServices");

exports.getServices = async () => {
    await updateServices();

    return {
        ...rootNode,
        children: [{
            ...allServices.blockchain,
            children: [
                allServices.blocks.getJson(),
            ]
        }, {
            ...allServicesBnb.blockchain,
            children: [
                allServicesBnb.blocks.getJson(),
            ]
        }]
    }

    //return allServices.blocks.getJson();
}

