const {blocksService} = require("./services");
const {allServices} = require("./allServices");
exports.updateBlockNumber = (blockNumber, head) => {
    console.log(`Updating block to ${blockNumber}`);
    blocksService.block_number = blockNumber;

    if(!head) {
        console.log('head undefined');
        blocksService.color = 'limegreen';
        return;
    }

    allServices.blockchain.attributes.block_number = head;
    const target = head - 15;

    if(target - blockNumber < 3) {
        blocksService.color = 'limegreen';
    } else if(target - blockNumber < 10) {
        blocksService.color = 'lime';
    } else if(target - blockNumber < 20) {
        blocksService.color = 'yellow';
    } else {
        blocksService.color = 'red';
    }
}