class Service {
    serviceName;
    serviceIdentifier;
    location;
    logging;
    deploymentName;
    usesProcessLog;  // if false -> check table
    isHourly;  // if true -> checks timestamp instead of block_number
    parentProcessName;
    parent;
    process_name;
    table_name;
    block_number;
    last_processed;
    color;
    children = [];

    constructor(
        serviceName,
        serviceIdentifier,
        usesProcessLog,
        isHourly,
        parentProcessName,
        process_name,
        table_name,
        location,
        logging,
        deploymentName,
    ) {
        this.serviceName = serviceName;
        this.serviceIdentifier = serviceIdentifier;
        this.usesProcessLog = usesProcessLog;
        this.isHourly = isHourly;
        this.parentProcessName = parentProcessName;
        this.process_name = process_name;
        this.table_name = table_name;
        this.color = 'limegreen';
        this.location = location;
        this.logging = logging;
        this.deploymentName = deploymentName;
    }

    addParent(parent) {
        this.parent = parent;
    }

    addChild(child) {
        if(!this.doesChildExist(child.serviceIdentifier)) {
            this.children.push(child);
        }
    }

    doesChildExist(identifier) {
        for(const child of this.children) {
            if(child.serviceIdentifier === identifier) {
                return true;
            }
        }

        return false;
    }

    getJson() {
        const baseJson = {
            name: this.serviceName,
            attributes: {
                serviceName: this.serviceName,
                serviceIdentifier: this.serviceIdentifier,
                location: this.location,
                logging: this.logging,
                deploymentName: this.deploymentName,
                usesProcessLog: this.usesProcessLog || false,
                isHourly: this.isHourly || false,
                parentProcessName: this.parentProcessName || '',
                process_name: this.process_name || '',
                table_name: this.table_name || '',
                block_number: this.block_number || 0,
                last_processed: this.last_processed || '',
                color: this.color,
            },
            children: [],
        }

        for(const child of this.children) {
            baseJson.children.push(child.getJson())
        }

        return JSON.parse(JSON.stringify(baseJson));
    }
}

exports.Service = Service;
