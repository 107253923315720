const { allServicesBnb} = require("./allServices");
const {blocksServiceBnb} = require("./servicesBnb");

exports.updateBlockNumberBnb = (blockNumber, head) => {
    if(blockNumber) {
        console.log(`Updating block to ${blockNumber}`);
        blocksServiceBnb.block_number = blockNumber; // TODO - update bnb service
    } else {
        console.log('Block number undefined!');
    }

    if(head) {
        allServicesBnb.blockchain.attributes.block_number = head;
    } else {
        console.log('head undefined');
    }

    if(!!head && !!blockNumber) {
        const target = head - 15;

        if(target - blockNumber < 3) {
            blocksServiceBnb.color = 'limegreen';
        } else if(target - blockNumber < 10) {
            blocksServiceBnb.color = 'lime';
        } else if(target - blockNumber < 20) {
            blocksServiceBnb.color = 'yellow';
        } else {
            blocksServiceBnb.color = 'red';
        }
    }
}