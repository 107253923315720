const {nativePriceUsdServiceBnb} = require("./servicesBnb");
exports.updateEthPriceBnb = (blockNumber, head) => {
    console.log(`Updating eth_price block to ${blockNumber}`);
    nativePriceUsdServiceBnb.block_number = blockNumber;

    if(head - blockNumber < 3) {
        nativePriceUsdServiceBnb.color = 'limegreen';
    } else if(head - blockNumber < 10) {
        nativePriceUsdServiceBnb.color = 'lime';
    } else if(head - blockNumber < 20) {
        nativePriceUsdServiceBnb.color = 'yellow';
    } else {
        nativePriceUsdServiceBnb.color = 'red';
    }
}