const {allServicesBnb} = require("./allServices");
exports.updateProcessBnb = (processName, blockNumber, lastProcessed, head) => {
    if(!allServicesBnb.hasOwnProperty(processName)) {
        //console.log(`Service ${processName} not found!`);
        return;
    }

    const service = allServicesBnb[processName];

    if(service.isHourly) {
        console.log(`Updating ${processName} to timestamp  ${lastProcessed}`);
        service.last_processed = lastProcessed;

        const lastProcessedDate = new Date(lastProcessed);
        const now = new Date();
        const deltaMs = now.getTime() - lastProcessedDate.getTime();
        const deltaSeconds = deltaMs / 1000.0;
        const ONE_HOUR = 60 * 60;
        const TWO_HOUR = 2 * ONE_HOUR;
        const THREE_HOUR = 3 * ONE_HOUR;

        if(deltaSeconds < ONE_HOUR) {
            service.color = 'limegreen';
        } else if(deltaSeconds < TWO_HOUR) {
            service.color = 'lime';
        } else if(deltaSeconds < THREE_HOUR) {
            service.color = 'yellow';
        } else {
            service.color = 'red';
        }
    } else {
        console.log(`Updating ${processName} to block  ${blockNumber}`);
        service.block_number = blockNumber;

        if(head - blockNumber < 3) {
            service.color = 'limegreen';
        } else if(head - blockNumber < 10) {
            service.color = 'lime';
        } else if(head - blockNumber < 20) {
            service.color = 'yellow';
        } else {
            service.color = 'red';
        }
    }
}