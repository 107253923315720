const {BACKEND_API_URL} = require("../data/constants");
const axios = require("axios").default;

exports.getBlockNumber = async (network) => {
    try {
            //const response = await fetch(`${BACKEND_API_URL}/${network || 'eth'}/blockNumber`);
            const response = await axios.get(`${BACKEND_API_URL}/${network || 'eth'}/blockNumber`);
            console.log(response);
            return response.data;
    } catch (e) {
        console.log('Error fetching blockNumber');
        console.log(e);
        return 'nothing';
    }
}
