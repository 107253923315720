const {Service} = require("./Service");

// =================================================================
//
//  LEVEL 1
//
// =================================================================

exports.blocksService = new Service(
    'Block Headers',
    'blocks',
    false,
    false,
    undefined,
    undefined,
    'blocks',
    'GKE',
    'google-cloud',
    'prod-eth-new-block-headers'
);

// =================================================================
//
//  LEVEL 2
//
// =================================================================

exports.pairEventFilterService = new Service(
    'Pair Event Filter',
    'pair_event_filter',
    true,
    false,
    'blocks',
    'pair_event_filter',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-pair-event-filter'
);

exports.eventCountsService = new Service(
    'Event Counts',
    'event_count',
    true,
    false,
    'blocks',
    'event_count',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-event-count'
);

exports.v3EventFilterService = new Service(
    'V3 Event Filter',
    'uniswapv3_event_filter',
    true,
    false,
    'blocks',
    'uniswapv3_event_filter',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-v3-event-filter'
);

exports.blockHoursService = new Service(
    'Block Hours',
    'block_hours',
    false,
    true,
    'blocks',
    undefined,
    'block_hours',
    'GKE',
    'google-cloud',
    'prod-eth-new-block-headers'
);

// =================================================================
//
//  LEVEL 3
//
// =================================================================

// pair event consolidation
exports.pairEventConsolidatorService = new Service(
    'Pair Event Consolidator',
    'v2_pair_event_consolidator',
    true,
    false,
    'pair_event_filter',
    'v2_pair_event_consolidator',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-pair-event-consolidator'
);

// v3 event consolidator
exports.v3EventConsolidatorService = new Service(
    'Univ3 Event Consolidator',
    'v3_event_consolidator',
    true,
    false,
    'uniswapv3_event_filter',
    'v3_event_consolidator',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-v3-event-consolidator'
);

// =================================================================
//
//  LEVEL 4 - pair event tail
//
// =================================================================

// eth price
exports.nativePriceUsdService = new Service(
    'Native Price USD',
    'eth_price',
    true,
    false,
    'v2_pair_event_consolidator',
    undefined,
    'eth_price',
    'GKE',
    'google-cloud',
    'prod-eth-native-price-usd'
);

// token total supply
exports.tokenTotalSupplyService = new Service(
    'Token Total Supply',
    'token_total_supply',
    true,
    false,
    'v2_pair_event_consolidator',
    'token_total_supply',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-token-total-supply'
);

// token total supply consolidator
exports.tokenTotalSupplyConsolidatorService = new Service(
    'Token Total Supply Consolidator',
    'token_total_supply_consolidator',
    true,
    false,
    'token_total_supply',
    'token_total_supply_consolidator',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-token-total-supply-consolidator'
);

// univ2 lp summarizer
exports.univ2LpSummarySchedulerService = new Service(
    'Univ2 LP Summary Scheduler',
    'univ2_lp_summary_query_scheduler',
    true,
    true,
    'token_total_supply_consolidator',
    'univ2_lp_summary_query_scheduler',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_1_eth_205_univ2_lp_summary_query_scheduler.service'
);

// exchange rate populator
exports.exchangeRatePopulatorSchedulerService = new Service(
    'Exchange Rate Populator Multichain Scheduler',
    'exchange_rate_populator_multichain',
    true,
    true,
    'eth_price',
    'exchange_rate_populator_multichain',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_0_multi_001_exchange_rate.service'
);

// =================================================================
//
//  LEVEL 4 - v3 event tail # 1: pool reserves -> v3 lp summary
//
// =================================================================

// v3_pool_reserves
exports.v3PoolReservesService = new Service(
    'V3 Pool Reserves',
    'v3_pool_reserves',
    true,
    false,
    'v3_event_consolidator',
    'v3_pool_reserves',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_1_eth_206_v3_pool_reserves_scheduler.service'
);

// univ3_return_scheduler
exports.Univ3ReturnSchedulerService = new Service(
    'Univ3 Return Scheduler',
    'univ3_return_scheduler',
    true,
    true,
    'v3_pool_reserves',
    'univ3_return_scheduler',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_1_eth_207_univ3_return_scheduler.service'
);

// univ3_lp_summary_query_scheduler
exports.Univ3LpSummarySchedulerService = new Service(
    'Univ3 LP Summary Scheduler',
    'univ3_lp_summary_query_scheduler',
    true,
    true,
    'univ3_return_scheduler',
    'univ3_lp_summary_query_scheduler',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_1_eth_208_univ3_lp_summary_query_scheduler.service'
);

// =================================================================
//
//  LEVEL 4 - v3 event tail # 1: v3 nfts -> v3 liquidity position qa
//
// =================================================================

// v3_liquidity_position_manager
exports.v3LiquidityPositionManagerService = new Service(
    'Univ3 Liquidity Position Manager',
    'v3_liquidity_position_manager',
    true,
    false,
    'v3_event_consolidator',
    'v3_liquidity_position_manager',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-v3-liquidity-monitor'
);

// v3_liquidity_position_consolidator
exports.v3LiquidityPositionConsolidatorService = new Service(
    'Univ3 Liquidity Position Consolidator',
    'v3_liquidity_position_consolidator',
    true,
    false,
    'v3_liquidity_position_manager',
    'v3_liquidity_position_consolidator',
    undefined,
    'GKE',
    'google-cloud',
    'prod-eth-v3-liquidity-consolidator'
);

// univ3_summary_supplementary_query_manager
exports.v3SummarySupplementalManagerService = new Service(
    'Univ3 Summary Supplemental Query Manager',
    'univ3_summary_supplementary_query_manager',
    true,
    true,
    'v3_liquidity_position_consolidator',
    'univ3_summary_supplementary_query_manager',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_1_eth_210_univ3_summary_supplementary_scheduler.service'
);

// uniswapv3_position_balances_update_completed
// exports.uniswapV3PositionBalancesUpdateService = new Service(
//     'Univ3 Liquidity Position Balances Update',
//     'uniswapv3_position_balances_update_completed',
//     true,
//     true,
//     undefined, // TODO
//     'uniswapv3_position_balances_update_completed',
//     undefined,
// );

// // v3_liquidity_position_balances_qa
// exports.v3LiquidityPositionBalanceQaService = new Service(
//     'Univ3 Liquidity Position Balances QA',
//     'v3_liquidity_position_balances_qa',
//     true,
//     true,
//     'uniswapv3_position_balances_update_completed',
//     'v3_liquidity_position_balances_qa',
//     undefined,
//     'GKE',
//     'google-cloud',
//     'prod-eth-new-block-headers'
// );
