const {blockHoursServiceBnb} = require("./servicesBnb");
exports.updateBlockHoursBnb = (timestamp, blockNumber, head) => {
    console.log(`Updating block_hours timestamp to ${timestamp}`);
    blockHoursServiceBnb.last_processed = timestamp;

    const BLOCKS_PER_HOUR = 1200;

    if(head - blockNumber < BLOCKS_PER_HOUR + 3) {
        blockHoursServiceBnb.color = 'limegreen';
    } else if(head - blockNumber < BLOCKS_PER_HOUR + 10) {
        blockHoursServiceBnb.color = 'lime';
    } else if(head - blockNumber < BLOCKS_PER_HOUR + 20) {
        blockHoursServiceBnb.color = 'yellow';
    } else {
        blockHoursServiceBnb.color = 'red';
    }
}