const {BACKEND_API_URL} = require("../data/constants");
exports.getLatestBlock = async (network) => {
    try {
        const response = await fetch(`${BACKEND_API_URL}/${network || 'eth'}/blocks`);
        return response.json();
    } catch (e) {
        console.log('Error fetching block')
        console.log(e);
        return 'nothing';
    }
}
