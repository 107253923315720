const {
    blockHoursService,
    blocksService,
    eventCountsService,
    nativePriceUsdService,
    pairEventConsolidatorService,
    pairEventFilterService,
    tokenTotalSupplyConsolidatorService,
    tokenTotalSupplyService,
    univ2LpSummarySchedulerService,
    v3EventConsolidatorService,
    v3EventFilterService,
    exchangeRatePopulatorSchedulerService,
    v3PoolReservesService,
    Univ3ReturnSchedulerService,
    Univ3LpSummarySchedulerService,
    v3LiquidityPositionManagerService,
    v3LiquidityPositionConsolidatorService,
    v3SummarySupplementalManagerService,
}= require("./services");
const {blocksServiceBnb, pairEventFilterServiceBnb, eventCountsServiceBnb, blockHoursServiceBnb,
    pairEventConsolidatorServiceBnb, nativePriceUsdServiceBnb, tokenTotalSupplyServiceBnb,
    tokenTotalSupplyConsolidatorServiceBnb, univ2LpSummarySchedulerServiceBnb
} = require("./servicesBnb");

const allServices = {
    'blockchain': {
        name: 'Ethereum Mainnet',
        attributes: {
            block_number: 0,
            isHourly: false,
            color: 'limegreen',
        }
    },
    'blocks': blocksService,
    'pair_event_filter': pairEventFilterService,
    'event_count': eventCountsService,
    'uniswapv3_event_filter': v3EventFilterService,
    'block_hours': blockHoursService,
    'v2_pair_event_consolidator': pairEventConsolidatorService,
    'v3_event_consolidator': v3EventConsolidatorService,
    'eth_price': nativePriceUsdService,
    'exchange_rate_populator_multichain': exchangeRatePopulatorSchedulerService,  // eth_price
    'token_total_supply': tokenTotalSupplyService,
    'token_total_supply_consolidator': tokenTotalSupplyConsolidatorService,
    'univ2_lp_summary_query_scheduler': univ2LpSummarySchedulerService,
    'v3_pool_reserves': v3PoolReservesService,  // v3_event_consolidator
    'univ3_return_scheduler': Univ3ReturnSchedulerService,  // v3_pool_reserves
    'univ3_lp_summary_query_scheduler': Univ3LpSummarySchedulerService,  // univ3_return_scheduler
    'v3_liquidity_position_manager': v3LiquidityPositionManagerService,  // v3_event_consolidator
    'v3_liquidity_position_consolidator': v3LiquidityPositionConsolidatorService,  // v3_liquidity_position_manager
    'univ3_summary_supplementary_query_manager': v3SummarySupplementalManagerService,  // v3_liquidity_position_consolidator
    // 'uniswapv3_position_balances_update_completed': undefined,  // TODO - confirm
    // 'v3_liquidity_position_balances_qa': undefined,  // uniswapv3_position_balances_update_completed
}

const allServicesBnb = {
    'blockchain': {
        name: 'BNB Smart Chain',
        attributes: {
            block_number: 0,
            isHourly: false,
            color: 'limegreen',
        }
    },
    'blocks': blocksServiceBnb,
    'pair_event_filter': pairEventFilterServiceBnb,
    'event_count': eventCountsServiceBnb,
    //'uniswapv3_event_filter': v3EventFilterService,
    'block_hours': blockHoursServiceBnb,
    'v2_pair_event_consolidator': pairEventConsolidatorServiceBnb,
    //'v3_event_consolidator': v3EventConsolidatorService,
    'eth_price': nativePriceUsdServiceBnb,
    //'exchange_rate_populator_multichain': exchangeRatePopulatorSchedulerService,  // eth_price
    'token_total_supply': tokenTotalSupplyServiceBnb,
    'token_total_supply_consolidator': tokenTotalSupplyConsolidatorServiceBnb,
    'univ2_lp_summary_query_scheduler': univ2LpSummarySchedulerServiceBnb,
    //'v3_pool_reserves': v3PoolReservesService,  // v3_event_consolidator
    //'univ3_return_scheduler': Univ3ReturnSchedulerService,  // v3_pool_reserves
    //'univ3_lp_summary_query_scheduler': Univ3LpSummarySchedulerService,  // univ3_return_scheduler
    //'v3_liquidity_position_manager': v3LiquidityPositionManagerService,  // v3_event_consolidator
    //'v3_liquidity_position_consolidator': v3LiquidityPositionConsolidatorService,  // v3_liquidity_position_manager
    //'univ3_summary_supplementary_query_manager': v3SummarySupplementalManagerService,  // v3_liquidity_position_consolidator
    // 'uniswapv3_position_balances_update_completed': undefined,  // TODO - confirm
    // 'v3_liquidity_position_balances_qa': undefined,  // uniswapv3_position_balances_update_completed
}

const rootNode = {
    name: 'FLUIDEFI',
    attributes: {
        block_number: 0,
        isHourly: false,
        color: 'limegreen',
    }
}

for(const serviceName of Object.keys(allServices)) {
    if(['blockchain'].includes(serviceName)) {
        continue;
    }

    const service = allServices[serviceName];

    if(service.parentProcessName) {
        const parentService = allServices[service.parentProcessName];

        if(parentService) {
            // set parent
            service.parent = parentService;

            // set as child
            parentService.addChild(service);
        } else {
            console.log(`Parent process ${service.parentProcessName} missing for service ${serviceName}`);
        }
    }
}

for(const serviceName of Object.keys(allServicesBnb)) {
    if(['blockchain'].includes(serviceName)) {
        continue;
    }

    const service = allServicesBnb[serviceName];

    if(service.parentProcessName) {
        const parentService = allServicesBnb[service.parentProcessName];

        if(parentService) {
            // set parent
            service.parent = parentService;

            // set as child
            parentService.addChild(service);
        } else {
            console.log(`Parent process ${service.parentProcessName} missing for service ${serviceName}`);
        }
    }
}

exports.allServices = allServices;
exports.allServicesBnb = allServicesBnb;
exports.rootNode = rootNode;

