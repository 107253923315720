const {nativePriceUsdService} = require("./services");
exports.updateEthPrice = (blockNumber, head) => {
    console.log(`Updating eth_price block to ${blockNumber}`);
    nativePriceUsdService.block_number = blockNumber;

    if(head - blockNumber < 3) {
        nativePriceUsdService.color = 'limegreen';
    } else if(head - blockNumber < 10) {
        nativePriceUsdService.color = 'lime';
    } else if(head - blockNumber < 20) {
        nativePriceUsdService.color = 'yellow';
    } else {
        nativePriceUsdService.color = 'red';
    }
}