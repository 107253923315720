import React, {useEffect, useState} from "react";
import Tree from "react-d3-tree";
import orgChartJson from "./data/org-chart.json";
import { useCenteredTree } from "./helpers";
import "./styles.css";
import {getServices} from "./data/getServices";
import {getProcessLog} from "./api/getProcessLog";
import {ENV_MODE} from "./data/constants";

const containerStyles = {
    width: "100vw",
    height: "100vh"
};

const ignoreList = [
    'FLUIDEFI',
    'Ethereum Mainnet',
    'BNB Smart Chain',
];

// Here we're using `renderCustomNodeElement` render a component that uses
// both SVG and HTML tags side-by-side.
// This is made possible by `foreignObject`, which wraps the HTML tags to
// allow for them to be injected into the SVG namespace.
const renderForeignObjectNode = ({
                                     nodeDatum,
                                     toggleNode,
                                     foreignObjectProps
                                 }) => (
    <g>
        <circle r={15}></circle>
        {/* `foreignObject` requires width & height to be explicitly set. */}
        <foreignObject {...foreignObjectProps}>
            <div style={{ border: "1px solid black", backgroundColor: "#dedede" }}>
                <h2 style={{ textAlign: "center" }}>{nodeDatum.name === 'FLUIDEFI' ? (<img style={{ maxHeight: "180px" }} src="logo.png" alt="FLUIDEFI" />) : nodeDatum.name}</h2>
                <h3 style={{ textAlign: "center" }}>{(nodeDatum.attributes && nodeDatum.attributes.isHourly) ? 'Last Processed: ' + nodeDatum.attributes.last_processed : ((nodeDatum.name === 'FLUIDEFI') ? '' : ('Block number: ' + (nodeDatum.attributes ? nodeDatum.attributes.block_number : 0)))}</h3>
                <h3 style={{ textAlign: "center" }}>{(ignoreList.includes(nodeDatum.name) ? '' : ('Run frequency: ' + ((nodeDatum.attributes && nodeDatum.attributes.isHourly) ? 'Every Hour' : 'Every Block')))}</h3>
                <h4 style={{
                    textAlign: "center"
                }}> {ENV_MODE === 'prod' ? '' : (ignoreList.includes(nodeDatum.name) ? '' : (nodeDatum.attributes ? nodeDatum.attributes.deploymentName : ''))} <br />{ENV_MODE === 'prod' ? '' : (ignoreList.includes(nodeDatum.name) ? '' : 'logs:')} {
                    ENV_MODE === 'prod' ? '' : (ignoreList.includes(nodeDatum.name) ? '' : (nodeDatum.attributes ? nodeDatum.attributes.logging : ''))
                }<br/> {ENV_MODE === 'prod' ? '' : (ignoreList.includes(nodeDatum.name) ? '' : 'deployed on:')} {
                    ENV_MODE === 'prod' ? '' : (ignoreList.includes(nodeDatum.name) ? '' : (nodeDatum.attributes ? nodeDatum.attributes.location : ''))
                }   </h4>
                {nodeDatum.children && (
                    <button style={{ width: "100%", backgroundColor: nodeDatum.attributes ? nodeDatum.attributes.color : 'grey' }} onClick={toggleNode}>
                        {nodeDatum.__rd3t.collapsed ? "Expand" : "Collapse"}
                    </button>
                )}
            </div>
        </foreignObject>
    </g>
);

const getData = async () => {
    // console.log('Getting process log');
    // const result = await getProcessLog();
    // console.log(result);
    console.log('Getting data');
    return await getServices();
}

export default function App() {
    const [translate, containerRef] = useCenteredTree();
    const nodeSize = { x: 400, y: 300 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 };

    const [data, setData] = useState({"name": "CEO", "children": []});

    useEffect(() => {
        getData()
            .then(_data =>
                setData(_data)
            );
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('Updating data');

            getData()
                .then(_data =>
                    setData(_data)
                );
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={containerStyles} ref={containerRef}>
            <Tree
                data={data}
                translate={translate}
                nodeSize={nodeSize}
                renderCustomNodeElement={(rd3tProps) =>
                    renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                }
                orientation="vertical"
            />
        </div>
    );
}
