const {BACKEND_API_URL} = require("../data/constants");
exports.getProcessLog = async (network) => {
    try {
            const response = await fetch(`${BACKEND_API_URL}/${network || 'eth'}/processLog`);
            return response.json();
    } catch (e) {
        console.log('Error fetching process log')
        console.log(e);
        return 'nothing';
    }
}
