const {BACKEND_API_URL} = require("../data/constants");
exports.getLatestEthPrice = async (network) => {
    try {
        const response = await fetch(`${BACKEND_API_URL}/${network || 'eth'}/ethPrice`);
        return response.json();
    } catch (e) {
        console.log('Error fetching eth price')
        console.log(e);
        return 'nothing';
    }
}
