const {blockHoursService} = require("./services");
exports.updateBlockHours = (timestamp, blockNumber, head) => {
    console.log(`Updating block_hours timestamp to ${timestamp}`);
    blockHoursService.last_processed = timestamp;

    const BLOCKS_PER_HOUR = 300;

    if(head - blockNumber < BLOCKS_PER_HOUR + 3) {
        blockHoursService.color = 'limegreen';
    } else if(head - blockNumber < BLOCKS_PER_HOUR + 10) {
        blockHoursService.color = 'lime';
    } else if(head - blockNumber < BLOCKS_PER_HOUR + 20) {
        blockHoursService.color = 'yellow';
    } else {
        blockHoursService.color = 'red';
    }
}