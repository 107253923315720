const {Service} = require("./Service");

// =================================================================
//
//  LEVEL 1
//
// =================================================================

exports.blocksServiceBnb = new Service(
    'Block Headers',
    'blocks',
    false,
    false,
    undefined,
    undefined,
    'blocks',
    'agg04',
    'systemd-journal',
    'agg_56_bsc_001_new_block_headers.service'
);

// =================================================================
//
//  LEVEL 2
//
// =================================================================

exports.pairEventFilterServiceBnb = new Service(
    'Pair Event Filter',
    'pair_event_filter',
    true,
    false,
    'blocks',
    'pair_event_filter',
    undefined,
    'GKE',
    'new-relic',
    'bnb-event-filter'
);

exports.eventCountsServiceBnb = new Service(
    'Event Counts',
    'event_count',
    true,
    false,
    'blocks',
    'event_count',
    undefined,
    'GKE',
    'new-relic',
    'bnb-event-count'
);

exports.blockHoursServiceBnb = new Service(
    'Block Hours',
    'block_hours',
    false,
    true,
    'blocks',
    undefined,
    'block_hours',
    'agg04',
    'systemd-journal',
    'agg_56_bsc_001_new_block_headers.service'
);

// =================================================================
//
//  LEVEL 3
//
// =================================================================

// pair event consolidation
exports.pairEventConsolidatorServiceBnb = new Service(
    'Pair Event Consolidator',
    'v2_pair_event_consolidator',
    true,
    false,
    'pair_event_filter',
    'v2_pair_event_consolidator',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_56_bsc_004_pair_event_consolidator.service'
);

// =================================================================
//
//  LEVEL 4 - pair event tail
//
// =================================================================

// eth price
exports.nativePriceUsdServiceBnb = new Service(
    'Native Price USD',
    'eth_price',
    true,
    false,
    'v2_pair_event_consolidator',
    undefined,
    'eth_price',
    'agg04',
    'systemd-journal',
    'agg_56_bsc_005_native_price_usd.service'
);

// token total supply
exports.tokenTotalSupplyServiceBnb = new Service(
    'Token Total Supply',
    'token_total_supply',
    true,
    false,
    'v2_pair_event_consolidator',
    'token_total_supply',
    undefined,
    'GKE',
    'new-relic',
    'bnb-token-total-supply'
);

// token total supply consolidator
exports.tokenTotalSupplyConsolidatorServiceBnb = new Service(
    'Token Total Supply Consolidator',
    'token_total_supply_consolidator',
    true,
    false,
    'token_total_supply',
    'token_total_supply_consolidator',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_56_bsc_007_lp_token_total_supply_consolidator.service'
);

// univ2 lp summarizer
exports.univ2LpSummarySchedulerServiceBnb = new Service(
    'Univ2 LP Summary Scheduler',
    'univ2_lp_summary_query_scheduler',
    true,
    true,
    'token_total_supply_consolidator',
    'univ2_lp_summary_query_scheduler',
    undefined,
    'agg04',
    'systemd-journal',
    'agg_56_bsc_205_univ2_lp_summary_query_scheduler.service'
);
