const {getProcessLog} = require("../api/getProcessLog");
const {getLatestBlock} = require("../api/getLatestBlock");
const {getLatestBlockHours} = require("../api/getLatestBlockHours");
const {getLatestEthPrice} = require("../api/getLatestEthPrice");
const {updateEthPrice} = require("./updateEthPrice");
const {updateBlockHours} = require("./updateBlockHours");
const {updateProcess} = require("./updateProcess");
const {updateBlockNumber} = require("./updateBlockNumber");
const {getBlockNumber} = require("../api/getBlockNumber");
const {updateBlockNumberBnb} = require("./updateBlockNumberBnb");
const {updateEthPriceBnb} = require("./updateEthPriceBnb");
const {updateBlockHoursBnb} = require("./updateBlockHoursBnb");
const {updateProcessBnb} = require("./updateProcessBnb");

exports.updateServices = async () => {
    // mainnet
    // ===================
    const blockNumberPromise = getBlockNumber();
    const processLogsPromise = getProcessLog();
    const blocksPromise = getLatestBlock();
    const blockHoursPromise = getLatestBlockHours();
    const ethPricePromise = getLatestEthPrice();

    // bnb
    // ===================
    const blockNumberPromiseBnb = getBlockNumber('bnb');
    const processLogsPromiseBnb = getProcessLog('bnb');
    const blocksPromiseBnb = getLatestBlock('bnb');
    const blockHoursPromiseBnb = getLatestBlockHours('bnb');
    const ethPricePromiseBnb = getLatestEthPrice('bnb');

    // eth
    // ===================
    const blockNumberResponse = await blockNumberPromise;
    const processLogs = await processLogsPromise;
    const blocks = await blocksPromise;
    const blockHours = await blockHoursPromise;
    const ethPrice = await ethPricePromise;
    let head = 0;

    // bnb
    // ===================
    const blockNumberResponseBnb = await blockNumberPromiseBnb;
    const processLogsBnb = await processLogsPromiseBnb;
    const blocksBnb = await blocksPromiseBnb;
    const blockHoursBnb = await blockHoursPromiseBnb;
    const ethPriceBnb = await ethPricePromiseBnb;
    let headBnb = 0;

    const blockNumber = blockNumberResponse ? blockNumberResponse.blockNumber : undefined;
    const blockNumberBnb = blockNumberResponseBnb ? blockNumberResponseBnb.blockNumber : undefined;

    console.log(`Got block number: ${blockNumber}`);
    console.log(`Got BNB block number: ${blockNumberBnb}`);

    // bnb
    // ===================

    if(!!blocksBnb && blocksBnb.length === 1) {
        updateBlockNumberBnb(blocksBnb[0].block_number, blockNumberBnb);
        headBnb = blocksBnb[0].block_number;
    } else {
        console.log('Did not receive valid block - BNB');
        updateBlockNumberBnb(undefined, blockNumberBnb);
    }

    if(ethPriceBnb && ethPriceBnb.length === 1) {
        updateEthPriceBnb(ethPriceBnb[0].block_number, headBnb);
    } else {
        console.log('Did not receive valid eth price - BNB');
    }

    if(blockHoursBnb && blockHoursBnb.length === 1) {
        // TODO - check against latest hour
        updateBlockHoursBnb(
            blockHoursBnb[0].hourly_timestamp_utc,
            blockHoursBnb[0].block_number,
            headBnb,
        );
    } else {
        console.log('Did not receive valid block hours - BNB');
    }

    if(processLogsBnb && processLogsBnb.length > 0) {
        const now = new Date();

        for(const processLog of processLogsBnb) {
            const {
                process_name,
                block_number,
                last_processed,
            } = processLog;

            updateProcessBnb(
                process_name,
                block_number,
                last_processed,
                headBnb,
            );
        }
    }  else {
        console.log('Did not receive valid process logs - BNB');
    }

    // mainnet
    // ===================

    if(blocks && blocks.length === 1) {
        updateBlockNumber(blocks[0].block_number, blockNumber);
        head = blocks[0].block_number;
    } else {
        console.log('Did not receive valid block');
    }

    if(ethPrice && ethPrice.length === 1) {
        updateEthPrice(ethPrice[0].block_number, head);
    } else {
        console.log('Did not receive valid eth price');
    }

    if(blockHours && blockHours.length === 1) {
        // TODO - check against latest hour
        updateBlockHours(
            blockHours[0].hourly_timestamp_utc,
            blockHours[0].block_number,
            head,
        );
    } else {
        console.log('Did not receive valid block hours');
    }

    if(processLogs && processLogs.length > 0) {
        const now = new Date();

        for(const processLog of processLogs) {
            const {
                process_name,
                block_number,
                last_processed,
            } = processLog;

            updateProcess(
                process_name,
                block_number,
                last_processed,
                head,
            );
        }
    }  else {
        console.log('Did not receive valid process logs');
    }
}